import './App.css';
import { Component } from 'react';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: '',
            queue: [
                {
                    id: '6043543543-leshrac',
                    matchID: '6043543543',
                    hero: 'leshrac',
                },
                {
                    id: '6043543543-leshrac',
                    matchID: '6043543543',
                    hero: 'leshrac',
                },
            ],
        };
    }

    updateSelection = (event) => {
        event.preventDefault();
        this.setState({
            selection: event.target.value,
        });
    };

    addToQueue = () => {
        let match = this.state.selection.split('-');
        if (match.length <= 1) {
            console.log('Invalid');
        } else {
            const matchObj = {
                id: match[0] + '-' + match[1],
                matchID: match[0],
                hero: match[1],
            };
            const newQueue = this.state.queue;
            newQueue.push(matchObj);
            this.setState({
                queue: newQueue,
            });
        }
    };

    removeFromQueue = (event) => {
        event.preventDefault();
        const removeID = event.target.id;

        let newQueue = this.state.queue;

        for (let i = 0; i < this.state.queue.length; i++) {
            if (newQueue[i].id === removeID) {
                newQueue.splice(i, 1);
            }
        }

        this.setState({
            queue: newQueue,
        });
    };

    render() {
        return (
            <div className="App">
                <h1 className="header"> Queue</h1>
                <h2> Add </h2>
                <input placeholder="matchid-hero" className="input" onChange={this.updateSelection} />
                <button onClick={this.addToQueue}>+</button>
                <h2> Oldest (Top) // Newest (Bottom) </h2>
                <div>
                    {this.state.queue.map((code, index) => (
                        <div className="text">
                            <span className="item">
                                {code.matchID}-{code.hero}
                            </span>
                            <button onClick={this.removeFromQueue} id={code.id}>
                                -
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
export default App;
